const disableBonus = (checkbox, disabled) => {
    checkbox.disabled = disabled
    const label = checkbox.labels[0]

    for (let c in label.children) {
        if (label.children[c].tagName === 'IMG') {
            label.children[c].classList.toggle('is-not-elligable', disabled)
            break
        }
    }
}

// Loops over all checkboxes and disabled all checkboxes not available with the current balance
const disableIfNotEnoughPointsAvailable = () => {
    const checkboxes = document.getElementsByClassName('bonus-check')
    let pointsAvailable = window.points

    // We'll need to get all points left before disabling the other checkboxes
    for (const ce of checkboxes) {
        const cost = parseInt(ce.dataset.cost)
        if (ce.checked) {
            pointsAvailable -= cost
        }
    }

    for (const ce of checkboxes) {
        const cost = parseInt(ce.dataset.cost)
        disableBonus(ce, pointsAvailable < cost && !ce.checked)
    }
}

const checkAvailablePoints = () => {
    const orderForm = document.getElementById('orderform')
    if (orderForm) {
        const checkboxes = document.getElementsByClassName('bonus-check')

        for (const c of checkboxes) {
            c.addEventListener('change', e => {
                disableIfNotEnoughPointsAvailable()
            })
        }
        disableIfNotEnoughPointsAvailable()
    }
}

document.addEventListener('readystatechange', checkAvailablePoints)
